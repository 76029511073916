<template>
  <div class="top" id="top">
    <div class="main mb-6">
      <section class="hero is-link is-fullheight-with-navbar hero-image">
        <div class="hero-head">
          <nav class="navbar" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
              <a class="navbar-item" @click="scrollTo('contact')">
                <h1>T.Errington</h1>
              </a>

              <a
                role="button"
                class="navbar-burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navMenu"
                @click="isOpen = !isOpen"
                v-bind:class="{ 'is-active': isOpen }"
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div
              id="navMenu"
              class="navbar-menu"
              v-bind:class="{ 'is-active': isOpen }"
            >
              <div class="navbar-end">
                <a
                  class="navbar-item"
                  @click="
                    isOpen = !isOpen;
                    scrollTo('projects');
                  "
                >
                  Projects
                </a>
                <a
                  class="navbar-item"
                  @click="
                    isOpen = !isOpen;
                    scrollTo('about');
                  "
                >
                  About
                </a>
                <a
                  class="navbar-item"
                  @click="
                    isOpen = !isOpen;
                    scrollTo('contact');
                  "
                >
                  Contact
                </a>
              </div>
            </div>
          </nav>
        </div>

        <div class="hero-body">
          <div class="container has-text-centered">
            <p class="title is-1 is-spaced">Thomas Errington</p>
            <a class="subtitle" @click="scrollTo('projects')">
              Web Developer | Data Scientist | Mobile Developer
            </a>
          </div>
        </div>
      </section>
    </div>

    <section class="section projects" id="projects">
      <h1 class="title-1 pb-3 info-title">Projects</h1>
      <div class="columns">
        <div class="column">
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <div
                class="tile is-child notification is-success float-tile"
                @click="isShow1 = !isShow1"
              >
                <div class="container">
                  <div class="card">
                    <div class="card-image">
                      <figure class="image is-4by3">
                        <img
                          src="@/assets/images/code.jpg"
                          alt="Web Development"
                        />
                      </figure>
                    </div>
                    <div class="card-content">
                      <div class="content">
                        <h3>Web Development</h3>
                        <div v-show="isShow1">
                          <p>
                            Over the past year or so, I decided to go on a
                            personal crusade to learn web development from top
                            to bottom. There were many times when I was outside
                            of my comfort zone, especially with obstacles that
                            were more design focused. However, I knew these were
                            the very tasks that I had to push through and master
                            to complete the journey.
                          </p>
                          <p>
                            I had a pretty good understanding of backend
                            development, databases and APIs using C# and Python,
                            but I knew front end development was going to be
                            most challenging part. I took small incremental
                            steps within different areas. In many cases, as I
                            began working down a particular path another area of
                            focus would present itself, and I would add it to
                            the to-do list.
                          </p>
                          <p>
                            Along the way I had the opportunity to try out
                            dozens of packages, libraries and frameworks all
                            claiming to do a task better than their competitors.
                            In many instances I would learn a couple of each
                            just to gain an understanding of why those claims
                            were made. In the end, I chose one from each area of
                            discipline to focus more heavily on.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <div
                class="tile is-child notification is-success float-tile"
                @click="isShow2 = !isShow2"
              >
                <div class="container">
                  <div class="card">
                    <div class="card-image">
                      <figure class="image is-4by3">
                        <img
                          src="@/assets/images/neural.jpg"
                          alt="Machine learning AI"
                        />
                      </figure>
                    </div>
                    <div class="card-content">
                      <div class="content">
                        <h3>Data Science</h3>
                        <div v-show="isShow2">
                          <p>
                            During my last year of college, I had the
                            opportunity to work on a capstone project that would
                            simulate the creation of a product for stakeholders.
                            I happened to get a project that required the use of
                            machine learning algorithms. Python was my preferred
                            language for a couple of years doing basic
                            programing tasks, but nothing on the level of
                            machine learning, image recognition, and neural
                            nets.
                          </p>
                          <p>
                            Ever since then it has been one of the few
                            programming paradigms that I keep coming back to.
                            Sifting through massive amounts of data, learning
                            about different areas of focus to improve feature
                            engineering, and testing various models with fingers
                            crossed has been an amazing. It gives me the
                            opportunity to understand what it takes to be a data
                            scientist whilst learning about different business
                            domains in the process.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <div
                class="tile is-child notification is-success float-tile"
                @click="isShow3 = !isShow3"
              >
                <div class="container">
                  <div class="card">
                    <div class="card-image">
                      <figure class="image is-4by3">
                        <img
                          src="@/assets/images/mobile.jpg"
                          alt="Mobile Development"
                        />
                      </figure>
                    </div>
                    <div class="card-content">
                      <div class="content">
                        <h3>Mobile Development</h3>

                        <div v-show="isShow3">
                          <p>
                            I built my first mobile app that got placed on the
                            Google Play Store in just nine months. I had
                            absolutely zero knowledge on how to design apps, but
                            through trial and error I eventually gained
                            confidence. The app was unique in that it used
                            Googles augmented reality library which, at the
                            time, had just been released. The library was still
                            a bit buggy; the app wasn’t the most gorgeous; but
                            the accomplishment of starting off with nothing and
                            just a short time later having an app people could
                            use was one of the greatest learning experiences.
                          </p>
                          <p>
                            A short time later I became a cross platform
                            developer in a professional environment. Many of the
                            things that were taught to me were obvious solutions
                            to the areas of struggle in my first app. Though I
                            still prefer native development, learning how to
                            develop an app to work on any device posed new
                            challenges and solutions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="section" id="about">
      <h1 class="info-title">About</h1>
      <div class="box m-6">
        <div class="columns">
          <div class="column">
            <div class="section">
              <p>
                I have been using computers since I was eight years old. My
                father showed me two important commands, “dir” and “cd”. For
                hours I would use them just to watch hundreds of files scroll
                across the screen. Many years later, I taught my son, at the age
                of eight, the same commands and had him code his first “Hello
                World” program using Python.
              </p>
            </div>
            <div class="section">
              <p>
                Since that first IBM PS/2 Model 70, I have seen many computers
                come and go and have continued to feed my curiosity. Everything
                from gaming to graphic design, to creating my own Linux kernel
                has been an area of obsession for a time. When I found
                programming though, I knew this was the area I wanted to focus
                on for a career.
              </p>
            </div>
          </div>
          <div class="column">
            <div class="section" id="me">
              <img src="@/assets/images/me.jpg" alt="Mobile Development" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section" id="contact">
      <h1 class="info-title">Contact</h1>
      <div class="columns">
        <div class="column">
          <div class="card m-6 p-6">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="name"
                  name="name"
                  placeholder="Your name"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input
                  class="input"
                  type="email"
                  v-model="email"
                  name="email"
                  placeholder="Your Email"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Message</label>
              <div class="control">
                <textarea
                  class="textarea"
                  v-model="message"
                  name="message"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button class="button is-link" @click="sendEmail()">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="box m-6 p-6">
            <div class="box float-tile" @click="linkedin()">Linkedin</div>
            <div class="box float-tile" @click="github()">Github</div>
            <div class="box float-tile" @click="kaggle()">Kaggle</div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>©️ 2022 Thomas Errington. All rights reserved.</p>
        <a @click="scrollTo('top')">Home</a>
      </div>
    </footer>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      isOpen: false,
      visibleId: null,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      name: "",
      email: "",
      message: "",
      active: false,
    };
  },
  methods: {
    scrollTo(id) {
      var goto = document.getElementById(id);
      goto.scrollIntoView({ behavior: "smooth" });
    },
    showContent(id) {
      var x = document.getElementById(id);
      if (x.style.display == "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    sendEmail(e) {
      try {
        emailjs.sendForm(
          "service_qmqj0lw",
          "template_3mm8bqe",
          e.target,
          "thomape217@gmail.com",
          {
            name: this.name,
            email: this.email,
            message: this.message,
          }
        );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    },
    linkedin: function () {
      window.open("https://www.linkedin.com/in/erringtontom/", "_blank");
    },
    github: function () {
      window.open("https://www.github.com/thomape", "_blank");
    },
    kaggle: function () {
      window.open("https://www.kaggle.com/thomaserrington", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.float-tile {
  cursor: pointer;
  transition: all 0.1s;
  &:hover {
    transform: scale(1.02, 1.02);
    box-shadow: 20px 20px 50px gray;
  }
}
.hero-image {
  background-image: url("@/assets/images/laptop.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
}
.top {
  background-color: black;
}
#projects p {
  text-align: justify;
}
.info-title {
  font-size: 2em;
}
#me img {
  height: 35em;
  width: auto;
  align-items: center;
}
</style>
